import React from 'react';
import { GateKind } from 'models/IGate';
import { AmazonBenefitData, AmazonBenefitGateSettings } from './AmazonBenefitGateSettings';
import PasswordGateSettings from './PasswordGateSettings';
import TicketSubscriptionBundleGateSettings from './TicketSubscriptionBundleGateSettings';
import { Feature } from 'services/feature-gate';
import IGate from 'models/IGate';
import { LogInGateSettings } from './LoginGateSettings';

export interface IAccessGateType {
  component?: any;
  dataTestId: string
  descriptionKey: string;
  featureGate?: string;
  iconKey: string;
  titleKey: string;
}

export interface IGateSettingsComponentProps {
  accessCodeEnabled: boolean;
  accessGateStateChanges: IGate
  amazonBenefitData: AmazonBenefitData;
  completeChanges: () => void;
  handleChangeGateClick: () => void;
  hasGateError: boolean;
  password: string;
  setAccessCodeEnabled: (value: boolean) => void;
  setAccessGateStateChanges: (value: IGate) => void;
  setAmazonBenefitData: (value: AmazonBenefitData) => void;
  setHasGateError: (value: boolean) => void;
  setIsGateOnFinalStep: (value: boolean) => void;
  setPassword: (value: string) => void;
  setShowAdvancedOptions: (value: boolean) => void;
  setVisibilityError: (value: boolean) => void;
  showAdvancedOptions: boolean;
  visibilityError: boolean;
}

export const gates: { [key: string]: IAccessGateType } = {
  [GateKind.PASSWORD]: {
    component: (props: IGateSettingsComponentProps) => (
      <PasswordGateSettings
        handleChangeGateClick={props.handleChangeGateClick}
        password={props.password}
        setHasGateError={props.setHasGateError}
        setIsGateOnFinalStep={props.setIsGateOnFinalStep}
        setPassword={props.setPassword}
      />
    ),
    dataTestId: 'passwordGate',
    iconKey: 'accessControlPassword',
    titleKey: 'PASSWORD',
    descriptionKey: 'ACCESS_CONTROL_PASSWORD_DESCRIPTION',
  },
  [GateKind.SUBSCRIPTION.toString()]: {
    component: (props: IGateSettingsComponentProps) => (
      <TicketSubscriptionBundleGateSettings
        accessCodeEnabled={props.accessCodeEnabled}
        accessGateStateChanges={props.accessGateStateChanges}
        setAccessCodeEnabled={props.setAccessCodeEnabled}
        setAccessGateStateChanges={props.setAccessGateStateChanges}
        setHasGateError={props.setHasGateError}
        setVisibilityError={props.setVisibilityError}
        showAdvancedOptions={props.showAdvancedOptions}
        handleChangeGateClick={props.handleChangeGateClick}
        setIsGateOnFinalStep={props.setIsGateOnFinalStep}
      />
    ),
    dataTestId: 'subscriptionGate',
    iconKey: 'accessControlTicketSubscription',
    titleKey: 'ACCESS_CONTROL_TICKET_BUNDLE_SUBSCRIPTION_TITLE',
    descriptionKey: 'ACCESS_CONTROL_TICKET_BUNDLE_SUBSCRIPTION_DESCRIPTION',
  },
  [GateKind.LOGIN.toString()]: {
    component: (props: IGateSettingsComponentProps) => (
      <LogInGateSettings
        handleChangeGateClick={props.handleChangeGateClick}
        setIsGateOnFinalStep={props.setIsGateOnFinalStep}
        setHasGateError={props.setHasGateError}
      />
    ),
    dataTestId: 'loginGate',
    iconKey: 'accessControlLogin',
    titleKey: 'LOG_IN',
    descriptionKey: 'ACCESS_CONTROL_LOGIN_DESCRIPTION',
  },
  [GateKind.AMAZONBENEFIT.toString()]: {
    component: (props: IGateSettingsComponentProps) => (
      <AmazonBenefitGateSettings
        handleChangeGateClick={props.handleChangeGateClick}
        amazonBenefitData={props.amazonBenefitData}
        setAmazonBenefitData={props.setAmazonBenefitData}
        setHasGateError={props.setHasGateError}
        setIsGateOnFinalStep={props.setIsGateOnFinalStep}
      />
    ),
    dataTestId: 'amazonBenefitGate',
    featureGate: Feature.AMAZON_BENEFIT,
    iconKey: 'accessControlAmazon',
    titleKey: 'ACCESS_CONTROL_AMAZON_BENEFIT_TITLE',
    descriptionKey: 'ACCESS_CONTROL_AMAZON_BENEFIT_DESCRIPTION',
  },
};

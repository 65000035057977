import styled, { css } from 'styled-components';
import { mobileOnly } from 'style/mixins';
import Header from 'components/header/Header';
import Icon from 'components/ui/Icon';
import { Button, CloseButton } from 'components/admin2/ui/Button';
import {
  ADMIN_SURFACE_1,
  ADMIN_SURFACE_3,
  ADMIN_TEXT_200,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_TEXT_100,
  ADMIN_SURFACE_5,
  SURFACE_4,
  ADMIN_TEXT_400,
  ADMIN_SURFACE_6,
  ADMIN_HIGHLIGHT_SECONDARY,
  MAESTRO_BLACK,
} from 'style/constants';
import { IAccessGateBackground, GateBackgroundEnum } from 'models/IGate';
import { rgba } from 'colors';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_S_REGULAR, ADMIN_TEXT_HEADLINE_S, ADMIN_TEXT_LABEL_XS_MEDIUM, ADMIN_TEXT_TITLE_M } from 'style/design-system/textStyles';

const DEFAULT_COLOR = MAESTRO_BLACK;
const GATE_ITEMS_WRAPPER_Z_INDEX = 1;

const renderGateBackground = (background: IAccessGateBackground) => {
  if (background?.type === GateBackgroundEnum.color) {
    return css`
      background-color: ${rgba(
        background?.color || DEFAULT_COLOR,
        background?.opacity || 1,
      )};
    `;
  }

  return css`
    background-image: url(${background?.desktopImage || ''});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  `;
};

export const GateContainer = styled.div<{ background: IAccessGateBackground}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0px 0px;
  background-color: ${SURFACE_4};
  ${({ background }) => renderGateBackground(background)}
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
`;

export const BackgroundButton = styled(Button)`
  position: absolute;
  top: 162px;
  right: 20px;
  ${ADMIN_TEXT_BODY_S_BOLD}
  padding: 12px 15.78px 12px 20px;
  height: 40px;
  min-width: 220px;
  z-index: ${GATE_ITEMS_WRAPPER_Z_INDEX + 1 };
`;

export const ChangeBackgroundIcon = styled(Icon)`
  color: ${ADMIN_TEXT_100};
  width: 15.44px;
  height: 15.44px;
  margin-right: 6.78px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
`;

export const StyledHeader = styled(Header)`
  height: 80px;
  margin: 0;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  margin-right: 30px;
  z-index: 4;
`;

export const GateData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  padding-left: 30px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const GateItemsWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  padding: 25px 0px;
  z-index:  ${GATE_ITEMS_WRAPPER_Z_INDEX};
`;

export const ClickableEditButton = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.5s ease-out;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  z-index: 10;

  &:hover {
    background-color: rgba(0,0,0,.3);
    opacity: 1;
  }
`;

export const Title = styled.div`
  color: ${ADMIN_TEXT_100};
  ${ADMIN_TEXT_HEADLINE_S}
  height: 36px;
  width: 100%;
`;

export const TextArea = styled.textarea`
  color: ${ADMIN_TEXT_400};
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  background: transparent;
  padding: 0;
  border: none;
  width: 100%;
`;

export const Subtitle = styled.div`
  color: ${ADMIN_TEXT_400};
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  width: 100%;
  white-space: pre-line;
`;

export const SetupAccessText = styled.div`
  height: 148px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${ADMIN_TEXT_BODY_S_BOLD}
`;

export const DateAndTimeModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 1000;
`;

// DATE AND TIME MODAL STYLED COMPONENTS
export const Container = styled.div`
    position: absolute;
    top: 80px;
    left: 240px;
    color: ${ADMIN_TEXT_100};
    padding: 20px;
    background-color: ${ADMIN_SURFACE_3};
    box-shadow: 0px 0px 2px -1px;
    border-radius: 5px;
    height: 298px;
    width: 418px;
    z-index: 100;
`;

export const ModalTitle = styled.p`
  color: ${ADMIN_TEXT_200};
  text-transform: uppercase;
  ${ADMIN_TEXT_BODY_S_BOLD}
  margin: 0;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CancelButton = styled(Button)`
  background-color: ${ADMIN_SURFACE_1};
  ${ADMIN_TEXT_BODY_S_REGULAR}
  width: 95px;
  height: 40px;
  margin-right: 10px;
`;

export const SaveButton = styled(Button)`
  ${ADMIN_TEXT_BODY_S_REGULAR}
  width: 107px;
  height: 40px;
`;

export const GradientMask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  margin: 0 !important;
`;

export const AccessSectionContainer = styled.div<{
  isAccessControlMode?: boolean;
  isSectionBlocked?: boolean;
  sectionTitle?: string;
  width?: string;
}>`
  padding: 16px 12px 16px 6px;
  width: ${({ width }) => width || 'inherit'};
  ${mobileOnly`
    width: 100%;
  `};
  ${({ isAccessControlMode, sectionTitle }) =>
    isAccessControlMode &&
    css`
      position: relative;
      border: 1px dashed ${ADMIN_ACCENT_PRIMARY};
      border-radius: 5px;
      &:after {
        ${ADMIN_TEXT_LABEL_XS_MEDIUM}
        position: absolute;
        top: 0;
        left: 0;
        content: "${sectionTitle || ''}";
        background-color: ${ADMIN_ACCENT_PRIMARY};
        color: ${ADMIN_TEXT_100};
        padding: 2px 15px;
        border-radius: 5px;
        text-transform: capitalize;
        z-index: 200;
      }

      &:hover {
        border: 1px solid ${ADMIN_HIGHLIGHT_SECONDARY};

        &:after {
          background-color: ${ADMIN_HIGHLIGHT_SECONDARY};
        }
      }
    `}
  ${({ isSectionBlocked }) =>
    css`
      pointer-events: ${isSectionBlocked ? 'none' : 'auto'};
    `}
`;

// Gate Card
export const GateCardWrapper = styled.div`
  width: 340px;
  height: 453px;
  position: absolute;
  top: 217px;
  right: 31px;
  z-index: ${GATE_ITEMS_WRAPPER_Z_INDEX + 1 };
`;

export const AccessControlElementsContainer = styled.div`
  margin-top: 20px;
`;

export const GateCardImageWrapper = styled.div`
  background: ${ADMIN_SURFACE_5};
  height: 175px;
  border: 0.965478px solid ${ADMIN_ACCENT_PRIMARY};
  border-radius: 9.65478px 9.65478px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GateCardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${ADMIN_SURFACE_6};
  color: ${ADMIN_TEXT_100};
  height: 278px;
  border-radius: 0px 0px 9.65478px 9.65478px;
  padding: 30px;
`;

export const StyledIcon = styled(Icon)`
  width: 96.09px;
  height: 75.86px;
`;

export const CardTitle = styled.h1`
  ${ADMIN_TEXT_TITLE_M}
  margin-top: 0px;
  margin-bottom: 5px;
`;

export const CardDescription = styled.p`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  opacity: 0.69;
`;

export const SetupAccessButton = styled(Button)`
  width: 161px;
  height: 40px;
  align-self: flex-end;
  ${ADMIN_TEXT_BODY_S_BOLD}
`;

export const ExitButton = styled(CloseButton).attrs(() => ({
  containerCss: `
    position: absolute;
    top: 10px;
    right: 10px;
    height: 21px;
    width: 21px;
  `,
}))``;
